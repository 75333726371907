<template>
	<el-card class="box-card">
	  <div slot="header" class="clearfix">
	    <h3 class="inBlock">秒杀列表</h3>
	  </div>
	  <div style=" padding:0 0 20px 0;">
	  		  <el-button @click="tab_click(index)" :type="num==index?'primary':''" v-for="(item,index) in tabs" :key="index">{{item}}</el-button>
	  		  <!-- <el-button></el-button>
	  		  <el-button></el-button> -->
	  		  <el-button style="float: right;" @click="add_coupon" type="success">添加活动</el-button>
	  </div>
	    <template>
	      <el-table
	        :data="tableData"
	        style="width: 100%">
	        <el-table-column
	          prop="title"
	          label="活动标题"
	          width="180">
	        </el-table-column>
	        <el-table-column
	          prop="type"
	          label="秒杀类型"
	          width="180">
	        </el-table-column>
	        <el-table-column
	          prop="count"
	          label="秒杀库存">
	        </el-table-column>
			<el-table-column
			  prop="address"
			  label="秒杀时间">
			  <template slot-scope="scope">
			  	{{scope.row.start_time|datefilter}}-{{scope.row.end_time|datefilter}}
			  </template>
			</el-table-column>
			<el-table-column
			  prop="update_time"
			  label="发布时间">
			</el-table-column>
			<el-table-column
			  label="操作">
			  <template slot-scope="scope">
					<el-button @click="handleClick(scope.row.id)" type="text" size="small">删除</el-button>
					<el-button @click="bianji(scope.row.id)" type="text" size="small">编辑</el-button>
			  </template>
			</el-table-column>
	      </el-table>
	    </template>
		<el-dialog
		  title="添加活动"
		  :visible.sync="dialogVisible"
		  width="40%"
		  >
		  <el-form ref="form" :model="form" label-width="100px">
		    <el-form-item label="活动标题">
		      <el-input v-model="form.title"></el-input>
		    </el-form-item>
			<el-form-item label="秒杀类型">
			  <!-- <el-radio-group v-model="form.type"> -->
			    <el-radio v-model="form.type" :label="1">限时</el-radio>
			    <el-radio v-model="form.type" :label="2">限量</el-radio>
			  <!-- </el-radio-group> -->
			</el-form-item>
			<el-form-item label="秒杀库存">
			  <el-input v-model="form.count"></el-input>
			</el-form-item>
			<!-- <el-form-item label="发放总数量">
			  <el-input v-model="form.name"></el-input>
			</el-form-item>	 -->	
			<el-form-item label="活动时间">
			  <el-col :span="11">
			    <el-date-picker
			          v-model="form.start_time"
			          type="datetime"
					  value-format="timestamp"
			          placeholder="开始时间">
			    </el-date-picker>
			  </el-col>
			  <el-col class="line" :span="2">-</el-col>
			  <el-col :span="11">
			    <el-date-picker
			          v-model="form.end_time"
			          type="datetime"
					  value-format="timestamp"
			          placeholder="结束时间">
			    </el-date-picker>
			  </el-col>
			</el-form-item>
			
		    <el-form-item label="选择商品">
		      <!-- <el-select v-model="form.goods" placeholder="请选择商品">
		        <el-option label="区域一" value="1"></el-option>
		        <el-option label="区域二" value="2"></el-option>
		      </el-select> -->
			  <el-select filterable style="width: 100%;" ref="select" v-model="form.goods" multiple placeholder="请选择">
			    <el-option
			      v-for="item in goodsdata"
			      :key="item.id"
			      :label="item.name"
			      :value="item.id">
			  		<span style="float: left; color: #8492a6;"><img style="width: 30px;" :src="imgurl+item.cover_pic" alt=""></span>
			  		<span style="float: left; margin-left: 10px;">{{ item.name }}</span>
			  						  
			    </el-option>
			  </el-select>
		    </el-form-item>
			



		    <el-form-item>
		      <el-button type="primary" @click="onSubmit">立即创建</el-button>
		      <el-button @click="quxiao">取消</el-button>
		    </el-form-item>
		  </el-form>
		  
		  <!-- <span slot="footer" class="dialog-footer">
		    <el-button @click="dialogVisible = false">取 消</el-button>
		    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
		  </span> -->
		</el-dialog>
		
		<el-pagination
		
		  @current-change="handleCurrentChange"
		  :page-size="100"
		  layout="prev, pager, next, jumper"
		  :total="total">
		</el-pagination>
	</el-card>  
</template>

  <script>
	  import request from "@/api/http.js";
	  import {getSystemsettings} from '../../api/getSystemsetting.js'
	  import {
	    seckillList,
		getGoods,
		saveSeckill,
		delSeckill,
		getSeckillDetail
	  } from "@/api/api.js";
    export default {
      data() {
        return {
			dialogVisible:false,
			tabs:['全部', '限时秒杀','限量秒杀'],
			num:0,
			page:1,
			goodsdata:[],
          tableData: [],
		  total:0,
		  form: {
		        title:'',
				type:1,
				count:'',
				start_time:'',
				end_time:'',
				goods:[]
		        }
        }
      },
	  methods:{
		  //quxiao
		  quxiao(){
			  this.dialogVisible=false
			   this.form={
			         title:'',
			  		type:1,
			  		count:'',
			  		start_time:'',
			  		end_time:'',
			  		goods:[]
			         },
			  this.form.seckill_id=''	 
		  },
		  // 获取商品
		  async getGoods() {
		      const { data } = await getGoods({page:this.page});
		  			  console.log(data)
		  			  if(data.code!=200){
		  				  return this.$message.error(data.data);
		  			  }	
					  data.data.data.forEach((item,index)=>{
						  this.goodsdata.push(item) 
					  })
		  					 
		  					// this.goodsdata=data.data.data
		  },
		  // 提交
		  onSubmit() {
			console.log('submit!');
			this.saveSeckill()
			
		  },
		  async saveSeckill(){
			  this.form.start_time=this.form.start_time/1000
			  this.form.end_time=this.form.end_time/1000
			  let goods_ids=this.form.goods
			  this.form.goods=[]
			  goods_ids.forEach((item,index)=>{
				  let goods_id={
					  goods_id:item
				  }
				  this.form.goods.push(goods_id)
			  })
			  
			 const {data}= await saveSeckill(this.form)
			 console.log(data)
			 if(data.code!=200){
				 return this.$message.error(data.data);
			 }
			 this.page=1
			 this.dialogVisible=false
			 this.getlist()
			 this.form={
			       title:'',
					type:1,
					count:'',
					start_time:'',
					end_time:'',
					goods:[]
			       },
			this.form.seckill_id=''	   
			  // this.tableData=data.data.data			  
		  },
		  // fenye 
		  handleCurrentChange(val){
			  this.getlist(this.num,val)
		  },
		  // 列表
		  async getlist(type,page=1){
			  let datas={
				  page:page,
				  limit:'',
				  start_time:'',
				  end_time:'',
				  title:'',
				  type:type,
				}
			 const {data}= await seckillList(datas)
			 console.log(data)
			 if(data.code!=200){
				 return this.$message.error(data.data);
			 }
			  this.tableData=data.data.data
			  this.total=data.data.total
		  },
		  // 删除
		  async delSeckill(id){
			  const {data} =await delSeckill({seckill_id:id})
			  if(data.code!=200){
				  return this.$message.error(data.data);
			  }
			  this.getlist()
		  },
		  //编辑
		  bianji(id){
			  this.dialogVisible=true
			  this.getSeckillDetail(id)
			  this.form.goods=[]
		  },
		  // 秒杀详情
		  async getSeckillDetail(id){
			  let datas={
				  seckill_id:id
			  }
			  const {data}=await getSeckillDetail(datas)
			  if(data.code!=200){
				   return this.$message.error(data.data);
			  }
			  this.form=data.data[0]
			  let goods=[]
			  data.data[0].seckill_goods.forEach((item,index)=>{
				  // let goodsid={
					 //  item
				  // }
				  goods.push(item.goods_id)
				  this.form.goods=goods
			  })
			  
			  this.form.end_time=this.form.end_time*1000
			  this.form.start_time=this.form.start_time*1000
			  this.form.seckill_id=id
			  
		  },
		  // 分类切换
		  tab_click(index){
			 this.num=index 
			 // this.page=1
			 this.getlist(index,1)
		  },
		  scolling(){
		  	let e = this.$refs.select.$refs.scrollbar.$refs.wrap
		  	 if(this.noMore) return
		  	 // 到底时触发 loadMore
		  	 let loadMore = e.scrollHeight -  e.scrollTop <=  e.clientHeight
		  	 if(loadMore){
		  	   // this.loadMore()
		  	   this.page++
		  	   this.getGoods()
		  	   console.log('32')
		  	 }
		  },
		  handleClick(id){
			  this.delSeckill(id)
		  },
		  add_coupon(){
			  this.dialogVisible=true
			  setTimeout(()=>{
			  	this.$refs.select.$refs.scrollbar.$refs.wrap.addEventListener('scroll',this.scolling)
			  },200)
		  },
	  },
	  mounted(){
		  this.getlist()
		  this.getGoods()

		  getSystemsettings.then(res=>{
		  	this.imgurl=res
		  })
	  },
    }
  </script>

<style>
</style>
